import { HttpClient,HttpHeaders } from '@angular/common/http'; 
//import { UserMerchantData} from './common-function.service';
import { Injectable } from '@angular/core';
import { JwtHelperService } from "@auth0/angular-jwt";
// import { DataService } from '../data.service';
import { Observable,  throwError,catchError, map, pipe } from 'rxjs';
import { environment } from 'src/environments/environment';

// import { BadInput } from 'src/app/common/bad-input';
// import { UnAuthorizeError } from 'src/app/common/unauthorize-error';
// import { NotFoundError } from 'src/app/common/not-found-error';
// import { AppError } from 'src/app/common/app-error';
// import { FormControl, Validators } from '@angular/forms';
// import { VposUserAppViewForPermissions } from '../../models/vpos-user-app-view-for-permissions';
// import { VposuserpermissionsAppview } from 'src/app/models/vposuserpermissions-appview';
 
// import { ConfirmPopupComponent } from '../../routes/confirm-popup/confirm-popup.component';
 


@Injectable()
export class CommonFunctionService {
  constructor(  private http: HttpClient) { }
  headers:any;
  token:any;
  getUserId() {
    const helper = new JwtHelperService();
    if (localStorage.getItem('VirtualPOSToken') != null) {
      let token :any = localStorage.getItem('VirtualPOSToken');
      let currentUser = helper.decodeToken(token);

      if(environment.clientsidelogflag ==0)
      {
        console.log('Current user ', currentUser);
      console.log(" userData ", currentUser['http://schemas.microsoft.com/ws/2008/06/identity/claims/userdata']);

      } 
  


           let userdata = currentUser['http://schemas.microsoft.com/ws/2008/06/identity/claims/userdata'];
      let useridfield = userdata.split('|');
      let userId = useridfield[0].split('UserId:')[1];
      if(environment.clientsidelogflag ==0)
      {
        console.log('UserId -', useridfield[0].split('UserId:')[1]);
      }
      return userId;
    }
    else {
      return "";
    }
  }

//pos


getUserPOSData() : any {
  let jwt = new JwtHelperService();
  if (localStorage.getItem('VirtualPOSToken') != null) {
    let token :any = localStorage.getItem('VirtualPOSToken');
      let currentUser = jwt.decodeToken(token);
    console.log('Current user ', currentUser);
    console.log(" userData ", currentUser['http://schemas.microsoft.com/ws/2008/06/identity/claims/userdata']);
    //let userdata = currentUser['http://schemas.microsoft.com/ws/2008/06/identity/claims/userdata'];
     let userdata = currentUser['http://schemas.microsoft.com/ws/2008/06/identity/claims/userdata'];
      let useridfield = userdata.split('|');
      let userId = useridfield[0].split('UserId:')[1];
      let userType = useridfield[1].split('UserType:')[1];
      let merchantkeyid = useridfield[2].split('MerchantKeyId:')[1];
      let tempPass = useridfield[3].split('TempPass:')[1];
      let passwordExpiryDate = useridfield[4].split('PasswordExpiryDate:')[1];
      let status = useridfield[5].split('Status:')[1];
 
    let posSubscriptionId = "PIN"+merchantkeyid ;
   
    console.log('posSubscriptionId -', posSubscriptionId);
   
    let UserPOSData: UserPOSData = {
      PosSubscriptionId: posSubscriptionId,
      UserId: userId,
      UserType: userType,
      TempPass: tempPass,
      PasswordExpiryDate: passwordExpiryDate
    }

     console.log('UserPOSData -', UserPOSData);
   

    return UserPOSData;
  }
  else {
    let UserPOSData: UserPOSData = {
      PosSubscriptionId: "",
      UserId: "",
      UserType: "",
      TempPass: "",
      PasswordExpiryDate: ""
    }
  }
}

  getUserMerchantData() : any {
    const helper = new JwtHelperService();
    if (localStorage.getItem('VirtualPOSToken') != null) {
      let token :any = localStorage.getItem('VirtualPOSToken');
      let currentUser = helper.decodeToken(token);
      if(environment.clientsidelogflag ==0)
      {
        console.log('Current user ', currentUser);
        console.log(" userData ", currentUser['http://schemas.microsoft.com/ws/2008/06/identity/claims/userdata']);
  
      }
        let userdata = currentUser['http://schemas.microsoft.com/ws/2008/06/identity/claims/userdata'];
      let useridfield = userdata.split('|');
      let userId = useridfield[0].split('UserId:')[1];
      let userType = useridfield[1].split('UserType:')[1];
      let merchantkeyid = useridfield[2].split('MerchantKeyId:')[1];
      let tempPass = useridfield[3].split('TempPass:')[1];
      let passwordExpiryDate = useridfield[4].split('PasswordExpiryDate:')[1];
      let status = useridfield[5].split('Status:')[1];
      let resellerId = useridfield[6].split('ResellerId:')[1];
      let confirmedEmail = useridfield[7].split('ConfirmedEmail:')[1];
      let confirmedMobile = useridfield[8].split('ConfirmedMobile:')[1];
      let contactNo = useridfield[10].split('ContactNo:')[1];
      let AccountLockDate= useridfield[11].split('AccountLockDate:')[1];
      let merchantCountryCode = useridfield[12].split('MerchantCountryCode:')[1];
      let isDefaultPassword = useridfield[13].split('IsDefaultPassword:')[1];
      
      if(environment.clientsidelogflag ==0)
      {
        console.log('UserId -', useridfield[0].split('UserId:')[1]);
      }

      let usermerchantdata: UserMerchantData = {
        MerchantKeyId: merchantkeyid,
        UserId: userId,
        UserType: userType,
        TempPass: tempPass,
        PasswordExpiryDate: passwordExpiryDate,
        Status:status,
        ResellerId: resellerId,
        ConfirmedEmail : confirmedEmail,
        ConfirmedMobile:confirmedMobile,
        ContactNo:contactNo,
        AccountLockDate:AccountLockDate,
        MerchantCountryCode:merchantCountryCode,
        IsDefaultPassword:isDefaultPassword
      }

      return usermerchantdata;
    }
    else {
      let usermerchantdata: UserMerchantData = {
        MerchantKeyId: 0,
        UserId: "",
        UserType: "",
        TempPass: "",
        PasswordExpiryDate: "",
        Status:"",
        ResellerId:0,
        ConfirmedEmail: "",
        ConfirmedMobile:"",
        ContactNo:"",
        AccountLockDate:"",
        MerchantCountryCode:"IN",
        IsDefaultPassword:0
      }
    }
  }

  getMerchantInfo(MerchantKeyId:any,emailUserName:any) {
    // return this.service
    //   .getAll(environment.getMerchantInfoUrl +"?MerchantKeyId=" + MerchantKeyId +"&EmailUsername="+emailUserName).pipe(map(response => response));
  }   

  GetMMSAuthToken() {
    const helper = new JwtHelperService();
    if (localStorage.getItem('PayGMMSToken') != null) {
      let token :any = localStorage.getItem('PayGMMSToken');
      let currentUser = helper.decodeToken(token);     
      let userdata = currentUser['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/hash'];
      return userdata;
       
    }
    else {
      return "";
    }
  }  

  getUserName() {
    const helper = new JwtHelperService();
    if (localStorage.getItem('VirtualPOSToken') != null) {
      let token :any = localStorage.getItem('VirtualPOSToken');
      let currentUser = helper.decodeToken(token);

      if(environment.clientsidelogflag ==0)
      {
        console.log('Current user ', currentUser);
        console.log(" userData ", currentUser['unique_name']);
      }

      return currentUser['unique_name']
    }
    else {
      return "";
    }
  }

  getEmail() {
    const helper = new JwtHelperService();
    if (localStorage.getItem('VirtualPOSToken') != null) {
      let token :any = localStorage.getItem('VirtualPOSToken');
      let currentUser = helper.decodeToken(token); 
      return currentUser['email']
    }
    else {
      return "";
    }
  }


  getSid() {
    const helper = new JwtHelperService();
    if (localStorage.getItem('VirtualPOSToken') != null) {
      let token :any = localStorage.getItem('VirtualPOSToken');
      let currentUser = helper.decodeToken(token);    
      // return currentUsers['sid']

      let userdata = currentUser['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/sid'];
      // alert(userdata);
      if(environment.clientsidelogflag ==0)
      {
        console.log('Sid ', userdata);
      }
     return userdata;
    }
    else {
      return "";
    }
  }

    getRolesPermissions()
  {


    let merchantdata: UserMerchantData = this.getUserMerchantData();  
    let email = this.getEmail();

    this.GetUserAuthdetails();
    //return 
    this.http
      .get<any>(environment.getRolesPermissions + "?MerchantKeyId=" + merchantdata.MerchantKeyId + "&Email=" + email,{ headers:this.headers})
      .pipe(map(response => response)); 
      return true;
           
  }

  // getUserPermissions()
  // {
  //   let merchantdata: UserMerchantData = this.getUserMerchantData();       
  //   this.http.get<VposuserpermissionsAppview>(environment.getUserPermissions + "?UserId=" + merchantdata.UserId)
  //     .map(response => response); 
  //     return true;
           
  // }

   GetuserPermissions(userId:any)
  {
   let res;
     if (localStorage.getItem('VirtualPOSUserPermission') == null) {
    res= this.http.get<any>(environment.getUserPermissions+"?UserId="+userId)   
    .pipe(map(response => response));

     localStorage.setItem("VirtualPOSUserPermission", JSON.stringify(res));
     }
     else
     {
       res= localStorage.getItem('VirtualPOSUserPermission');    
     }
     return res;
   // .catch(this.common.handleError);
  }

  getTransactionId() {
    return Math.floor(Math.random() * 10000000) + 1
  }

  getOrderId() {
    return Math.floor(Math.random() * 100000000) + 1
  }


   handleError(error: Response) {
    // console.log(error);
    // if (error.status === 400 || error.status === 412)
    //   return throwError(new BadInput(error.json()));
    //   else if (error.status === 401)
    //   return throwError(new UnAuthorizeError());
    // else if (error.status === 404)
    //   return throwError(new NotFoundError());
          
    // return throwError(new AppError(error));
    return;
  }


  GetAddOnBusinessTypes=["Education","Fee","Govt Agency","Insurance","Services"];
  GetAddOnBusinessTypess=[{text:"Fee", value:"Fee"},
  {text:"Govt Agency", value:"Govt Agency"},
  {text:"Insurance", value:"Insurance"}];
  GetEducationSubTyoes=["Play/Nursery school","School","College","University","Institutes","Tuition","Religious School"];
  GetServicesSubTypes =["Professional Services","Business Services","Catering Services","House Keeping","Logistic Services","Marketing Services","Transportation Services","Financial and taxation Services"];
  GetAddOnBusinesssubTypes=["School","Institute","Tution","University","Municipal","Traffic Challan","Life Insurance","Vehicle Insurance"];
  GetFeesubTypes=["Club","Sports Club","GYM and HealthCare","Hostel","TV/Wifi Cable","Food and Tiffin"];
  GetGovtAgencysubTypes=["Challan","Municipal Payments","Me-Sava/E-Mitra","Others"]; 
  GetInsurancesubTypes=["Accident","Child","Critical Illness","Disability","Endowment","Farming and Crops","Fire","General","Health","Landlords and Renters","Liability","Life","Pets and Cattles","Pension","Property","Trade Credit","Travel","Unemployment","Vehicle"];
  
  GetProductCategories = [
    {text:"Electronics & Appliances", value:"Electronics & Appliances"},
    {text:"Mobiles", value:"Mobiles"},
    {text:" Car, Bike & Accessories", value:" Car, Bike & Accessories"},
    {text:"Super Value Bazaar", value:"Super Value Bazaar"},
    {text:"Cosmetics & Jewelry", value:"Cosmetics & Jewelry"},
    {text:"Home & Toiletries", value:"Home & Toiletries"},
    {text:"Baby, Kids & Toys", value:"Baby, Kids & Toys"},
    {text:"Sports & Fitness", value:"Sports & Fitness"},
    {text:"Books & Stationery", value:"Books & Stationery"},
    {text:"Kitchen & Grocery", value:"Kitchen & Grocery"},
    {text:"Madison & Health care", value:"Madison & Health care"},
    {text:"Men's FASHION", value:"Men's FASHION"},
    {text:"Women's FASHION", value:"Women's FASHION"},
    {text:"Tools & Hardware bazaar", value:"Tools & Hardware bazaar"},
    {text:"Wholesale Bazaar", value:"Wholesale Bazaar"},
  ]

  SilverPackages=[{packageItem:"Transactions", value:"Transactions"},
  {packageItem:"Invoice", value:"Invoice"},
  {packageItem:"Bill Pay", value:"Bill Pay"},
  {packageItem:"Processing", value:"Processing"},
  {packageItem:"Reports", value:"Reports"},
  {packageItem:"Settings", value:"Settings"}]

  GoldPackages=[{packageItem:"Transactions", value:"Transactions"},
  {packageItem:"Invoice", value:"Invoice"},
  {packageItem:"Bill Pay", value:"Bill Pay"},
  {packageItem:"Processing", value:"Processing"},
  {packageItem:"Reports", value:"Reports"},
  {packageItem:"Settings", value:"Settings"},

  {packageItem:"Secure Vault", value:"Invoice"},
  {packageItem:"Prepaid Cards", value:"Bill Pay"},
  {packageItem:"Ecom Solutions", value:"Ecom Solutions"},
  {packageItem:"Notifications", value:"Notifications"}
]


  paltinumPackages=[{packageItem:"Transactions", value:"Transactions"},
  {packageItem:"Invoice", value:"Invoice"},
  {packageItem:"Bill Pay", value:"Bill Pay"},
  {packageItem:"Processing", value:"Processing"},
  {packageItem:"Reports", value:"Reports"},
  {packageItem:"Settings", value:"Settings"},

  {packageItem:"Secure Vault", value:"Invoice"},
  {packageItem:"Prepaid Cards", value:"Bill Pay"},
  {packageItem:"Ecom Solutions", value:"Ecom Solutions"},
  {packageItem:"Notifications", value:"Notifications"},

  {packageItem:"Future Billing", value:"Future Billing"},
  {packageItem:"AddOn Business", value:"AddOn Business"},
  {packageItem:"Products", value:"Products"},
  {packageItem:"Coupons", value:"Coupons"},

]

 TotalPackagesList = [{
      silver : "Transactions",
      gold: "Silver (Plus)",
      platinum : "Gold (Plus)"
  },
  {
    silver : "Invoice",
    gold: "Secure Vault",
    platinum : "Future Billing"
  },
  {
    silver : "Bill Pay",
    gold: "Prepaid Cards",
    platinum : "Add on Business"
  },
  {
    silver : "Processing",
    gold: "Ecom Solutions",
    platinum : "Products"
  },
  {
    silver : "Reports",
    gold: "Notifications",
    platinum : "Coupons"
  },
  {
    silver : "Settings",
    gold: "",
    platinum : ""
  }];


 // coupons
  GetCouponExpiryType = [
    {text:"Expired on date", value:"Expired on date"},
    {text:"Expired after times used", value:"Expired after times used"},
    {text:"Deal promo", value:"Deal promo"},
  ]
  // GetCouponExpiryType=["Expired on date,","Expired after times used","Deal promo"];

  GetCountries(){
    return [
    new Country(1, 'USA' ),
    new Country(2, 'India' )
    ]
  }

  getbusinessTypesList()
  {
    return [
      new BusinessTypes( 1, 'Sole Proprietorship' ),
      new BusinessTypes( 2, 'Private Limited' ),
      new BusinessTypes( 3, 'Public Limited' )   ,
      new BusinessTypes( 4, 'Partnership' ),
      new BusinessTypes( 5, 'Individual' ),
      new BusinessTypes( 6, 'NGO' )    ,
      new BusinessTypes( 7, 'Society' ),
      new BusinessTypes( 8, 'Trust' ),
      new BusinessTypes( 9, 'LLP' )  ,  
      new BusinessTypes( 10, 'Not yet registered' )
    ];
  }
  public getIPAddress()
  {
    return this.http.get("https://api.ipify.org/?format=json");
  }
  
getTurnOverAmountList()
{
  return [
    new BusinessTurnOver( 1, 'Less than 5 Lac' ),
    new BusinessTurnOver( 2, '5 Lacs to 25 Lacs' ),
    new BusinessTurnOver( 3, '25 Lacs to 50 Lacs' ),
    new BusinessTurnOver( 4, '50 Lacs to 1 Crore' ),
    new BusinessTurnOver( 5, 'More than 1 Crore' )
     
  ];
}

getTransactionReportTypesList()
{
  return [
    new TransactionReportTypes( 1, 'ToDay'),
    new TransactionReportTypes( 2, 'YesterDay'),
    new TransactionReportTypes( 3, 'This Week'),
    new TransactionReportTypes( 4, 'Last Week'),
    new TransactionReportTypes( 5, 'This Month'),
    new TransactionReportTypes( 5, 'Last Month'),
    new TransactionReportTypes( 5, 'Date Range')
  ];
}

getBusinessCategoryList()
{
  return [
    new BusinessCategory( 1, 'Automobiles and Vehicles' ),
    new BusinessCategory( 2, 'Beauty and Wellness' )  ,  
    new BusinessCategory( 3, 'Business Services' ),
    new BusinessCategory( 4, 'Cable and Broadband' ),
    new BusinessCategory( 5, 'Corporate Alliance' )  ,  
    new BusinessCategory( 6, 'Education' ),
    new BusinessCategory( 7, 'Entertainment' ),
    new BusinessCategory( 8, 'Event Services' )  ,  
    new BusinessCategory( 9, 'Food' ),
    new BusinessCategory( 10, 'Government' ),
    new BusinessCategory( 11, 'Healthcare' )  ,  
    new BusinessCategory( 12, 'Home Services' ),
    new BusinessCategory( 13, 'Housing' ),
    new BusinessCategory( 14, 'Individual Services' ) ,   
    new BusinessCategory( 15, 'LFR and Brands' ),
    new BusinessCategory( 16, 'Logistics' ),
    new BusinessCategory( 17, 'Milk Dairy and Cooperative' )  ,  
    new BusinessCategory( 18, 'NGO' ),
    new BusinessCategory( 19, 'Parking and Tolls' ),
    new BusinessCategory( 20, 'Personal Services' )    ,
    new BusinessCategory( 21, 'Retail and Shopping' ),
    new BusinessCategory( 22, 'Transport' ),
    new BusinessCategory( 23, 'Travel' )    ,
    new BusinessCategory( 24, 'Tuition and Classes' ),
    new BusinessCategory( 25, 'Utilities' ),
    new BusinessCategory( 26, 'Wholesale Trade and B2B' ),
    new BusinessCategory( 27, 'IT Solution'),
    new BusinessCategory( 28, 'Others')
  ];
}

 getTimeZones(){
  return [
  new TimeZone(1,'(UTC-08:00) Pacific Time (US & Canada)'),
  new TimeZone(2,'(UTC-07:00) Mountain Time (US & Canada)'),
  new TimeZone(3,'(UTC-06:00) Central Time (US & Canada)'),
  new TimeZone(4,'(UTC-05:00) Eastern Time (US & Canada)'),
  new TimeZone(4,'india'),
  ];
}

 getStates() {
  return [
  
    new State(1,2, 'Andhra Pradesh' ),
    new State(2,2, 'Arunachal Pradesh'),
    new State(3,2, 'Assam' ),
    new State(4,2, 'Bihar'),
    new State(5, 2,'Chandigarh'),
    new State(6,2, 'Chhattisgarh' ),
    new State(7,2, 'Dadra & Nagar Haveli' ),
    new State(8, 2,'Daman & Diu' ),
    new State(9,2, 'Delhi'),
    new State(10,2, 'Goa' ),
    new State(11, 2,'Gujarat' ),
    new State(12,2, 'Haryana'),
    new State(13, 2,'Himachal Pradesh'),
    new State(14, 2,'Jammu & Kashmir' ),
    new State(15,2,'Jharkhand'),
    new State(16,2,'Karnataka' ),
    new State(17,2,'Kerala' ),
    new State(18,2,'Lakshadweep' ),
    new State(19,2,'Madhya Pradesh'),     
    new State(20,2, 'Maharashtra' ),
    new State(21,2, 'Manipur' ),
    new State(22,2, 'Meghalaya'),
    new State(23,2, 'Mizoram'),
    new State(24,2, 'Nagaland' ),
    new State(25,2, 'Orissa'),
    new State(26,2, 'Pondicherry' ),
    new State(27,2, 'Punjab' ),
    new State(28,2, 'Rajasthan' ),
    new State(29,2, 'Sikkim'),
    new State(30,2, 'Tamil Nadu' ),
    new State(31,2, 'Telangana' ),
    new State(32,2, 'Tripura'),
    new State(33,2, 'Uttar Pradesh'),
    new State(34,2, 'Uttaranchal' ),
    new State(35,2, 'West Bengal')
   ];
 }
 

 
 

//  getCities() {
//   return [
//     new City( 1, 'Achabal' ),
//     new City(1, 'Akhnoor' ),
//     new City( 1, 'Anantnag'),
//     new City( 1, 'Arnia'),
//     new City( 1, 'Awantipora' ),
//     new City( 1, 'Badami Bagh'),
//     new City( 1, 'Badgam' ),
//     new City( 1, 'Bandipore' ),
//     new City( 1, 'Florida' ),
//     new City( 1, 'Georgia'),
//     new City( 1, 'Hawaii' ),
//     new City( 1, 'Idaho' ),
//     new City( 1, 'Illinois'),
//     new City( 1, 'Indiana'),
//     new City( 1, 'Iowa' ),
//     new City( 1, 'Kansas'),
//     new City( 1, 'Kentucky' ),
//     new City( 1, 'Louisiana' ),
//     new City( 1, 'Maine' ),
//     new City( 1, 'Maryland'),     
//    new City( 1, 'Massachusetts' ),
//    new City( 1, 'Michigan' ),
//    new City( 1, 'Minnesota'),
//    new City( 1, 'Mississippi'),
//    new City( 1, 'Missouri' ),
//    new City( 1, 'Montana'),
//    new City( 1, 'Nebraska' ),
//    new City( 1, 'Nevada' ),
//    new City( 1, 'New Hampshire' ),
//    new City( 1, 'New Jersey'),
//    new City(1, 'New Mexico' ),
//    new City( 1, 'New York' ),
//    new City(1, 'North Carolina'),
//    new City(1, 'North Dakota'),
//     new City(1, 'Ohio' ),
//     new City(1, 'Oklahoma'),
//     new City(1, 'Oregon' ),
//     new City(1, 'Pennsylvania' ),
//     new City(1, 'Rhode Island'),
//     new City(1, 'South Carolina'),
//     new City(1, 'South Dakota' ),
//     new City(1, 'Tennessee'),
//     new City(1, 'Texas' ),
//     new City(1, 'Utah' ),
//     new City(1, 'Vermont' ),
//     new City(1, 'Virginia'),    
//     new City(1, 'Washington' ),
//     new City(1, 'West Virginia' ),
//     new City(1, 'Wisconsin' ),
//     new City(1, 'Wyoming'),   
//     new City(2, 'Jammu & Kashmir' ),
//     new City(2, 'Himachal Pradesh' ),
//     new City(2, 'Chandigarh'),
//     new City(2, 'Punjab'),
//     new City(2, 'Haryana' ),
//     new City(2, 'Delhi'),
//     new City(2, 'Uttarakhand' ),
//     new City(2, 'Uttar Pradesh' ),
//     new City(2, 'Bihar' ),
//     new City(2, 'West Bengal'),
//     new City(2, 'Sikkim' ),
//     new City(2, 'Assam' ),
//     new City(2, 'Arunachal Pradesh'),
//     new City(2, 'Nagaland'),
//     new City(2, 'Manipur' ),
//     new City(2, 'Mizoram'),
//     new City(2, 'Tripura' ),
//     new City(2, 'Jharkhand' ),
//     new City(2, 'Orissa' ),
//     new City(2, 'Chhattisgarh'),     
//     new City(2, 'Madhya Pradesh' ),
//     new City(2, 'Goa' ),
//     new City(2, 'Daman & Diu'),
//     new City(2, 'Dadra & Nagar Haveli'),
//     new City(2, 'Maharashtra' ),
//     new City(2, 'Puducherry'),
//     new City(2, 'Karnataka' ),
//     new City(2, 'Andhra Pradesh' ),
//     new City(2, 'Kerala' ),
//     new City(2, 'Tamil Nadu'),
//     new City(2, 'Rajasthan' ),
//     new City(2, 'Gujarat' ),
//     new City(2, 'Andaman and Nicobar Islands'),
//     new City(2, 'Lakshadweep'),
//     new City(2, 'Meghalaya' ),
//     new City(2, 'Telangana')
//    ];
//  }

getUnits(){
  return [
    new Units( 1, 'packets' ),
    new Units( 1, 'Units' ),
    new Units( 1, 'Boxes' )    
  ];
}

getExpiryYearsList(){
  return [
  new Years(1,'2018'),
  new Years(2,'2019'),
  new Years(3,'2020'),
  new Years(4,'2021'),
  new Years(5,'2022'),
  new Years(6,'2023'),
  new Years(7,'2024'),
  new Years(8,'2025'),
  new Years(9,'2026'),
  new Years(10,'2027'),
  new Years(11,'2028'),
  new Years(12,'2029'),
  new Years(13,'2030'),
  new Years(14,'2031'),
  new Years(15,'2032'),
  ];
}
getExpiryMonthsList(){
  return [
  new Months(1,'01'),
  new Months(2,'02'),
  new Months(3,'03'),
  new Months(4,'04'),
  new Months(5,'05'),
  new Months(6,'06'),
  new Months(7,'07'),
  new Months(8,'08'),
  new Months(9,'09'),
  new Months(10,'10'),
  new Months(11,'11'),
  new Months(12,'12')
  ];
}


statesAndCities(){
  return [     
    new statecities(1,'Jammu & Kashmir' ),
    new statecities(2,'Himachal Pradesh' ),
    new statecities(3,'Chandigarh'),
    new statecities(4,'Punjab'),
    new statecities(5,'Haryana' ),
    new statecities(6,'Delhi'),
    new statecities(7,'Uttarakhand' ),
    new statecities(8,'Uttar Pradesh' ),
    new statecities(9,'Bihar' ),
    new statecities(10,'West Bengal'),
    new statecities(11,'Sikkim' ),
    new statecities(12,'Assam' ),
    new statecities(13,'Arunachal Pradesh'),
    new statecities(14,'Nagaland'),
    new statecities(15,'Manipur' ),
    new statecities(16,'Mizoram'),
    new statecities(17,'Tripura' ),
    new statecities(18,'Jharkhand' ),
    new statecities(19,'Orissa' ),
    new statecities(20,'Chhattisgarh'),     
    new statecities(21,'Madhya Pradesh' ),
    new statecities(22,'Goa' ),
    new statecities(23,'Daman & Diu'),
    new statecities(24,'Dadra & Nagar Haveli'),
    new statecities(25,'Maharashtra' ),
    new statecities(26,'Puducherry'),
    new statecities(27,'Karnataka' ),
    new statecities(28,'Andhra Pradesh' ),
    new statecities(29,'Kerala' ),
    new statecities(30,'Tamil Nadu'),
    new statecities(31,'Rajasthan' ),
    new statecities(32,'Gujarat' ),
    new statecities(33,'Andaman and Nicobar Islands'),
    new statecities(34,'Lakshadweep'),
    new statecities(35,'Meghalaya' ),
    new statecities(36,'Telangana'),
    new statecities(37,'Andhra Pradesh-Amaravathi'),
    new statecities(38,'Arunachal Pradesh-Itanagar'),
    new statecities(39,'Assam-Dispur'),
    new statecities(40,'Bihar-Patna'),
    new statecities(41,'Goa-Panaji'),
    new statecities(42,'Gujarat-Gandhinagar'),
    new statecities(43,'Haryana-Chandigarh'),
    new statecities(44,'Himachal Pradesh-Shimla'),
    new statecities(45,'Jammu & Kashmir-Srinagar'),
    new statecities(46,'Karnataka-Bangalooru'),
    new statecities(47,'Kerala-Thiruvananthapuram'),
    new statecities(48,'Madhya Pradesh-Bhopal'),
    new statecities(49,'Maharashtra-Mumbai'),
    new statecities(50,'Manipur-Imphal'),
    new statecities(51,'Meghalaya-Shillong'),
    new statecities(52,'Mizoram-Aizawl'),
    new statecities(53,'Nagaland-Kohima'),
    new statecities(54,'Orissa-Bhubaneswar'),
    new statecities(55,'Punjab-Chandigarh'),
    new statecities(56,'Rajasthan-Jaipur'),
    new statecities(57,'Sikkim-Gangtok '),
    new statecities(58,'Tamil Nadu-Chennai'),
    new statecities(59,'Tripura-Agartala'),
    new statecities(60,'Uttar Pradesh-Lucknow'),
    new statecities(61,'West Bengal-Kolkata'),
    new statecities(62,'Chhattisgarh-Raipur'),
    new statecities(63,'Uttarakhand-Dehradun'),
    new statecities(64,'Jharkhand-Ranchi'),
    new statecities(65,'Andaman and Nicobar Islands-Port Blair'),
    new statecities(66,'puducherry-Yanam')
    ];
  } 

  Permissions(){
    return [     
      new permissions(1,'Read'),
      new permissions(2,'Write'),
      new permissions(3,'Update'),
      new permissions(4,'Delete')
      ];
    } 
  
  
    getBankCodeTypes(){
      return [     
        new BankCodeTypes(1,'Routing Transit Number'),
        new BankCodeTypes(2,'Bank Sort Codes'),
        new BankCodeTypes(3,'Bsb Numbers'),
        new BankCodeTypes(4,'Swift Code'),
        new BankCodeTypes(5,'Micr Code'),
        new BankCodeTypes(6,'Ifsc Code')
        ];
      }
      getBankAccountTypes(){
        return [     
          new BankAccountTypes(1,'Personal Saving Account'),
          new BankAccountTypes(2,'Person Checking Account'),
          new BankAccountTypes(3,'Business Saving Account'),
          new BankAccountTypes(4,'Business Checking Account')
          ];
        }


        GetUserAuthdetails()
        {
          this.token ="" + ":" + environment.GetAuthenticationToken + ":" +'IT';
          this.headers = new HttpHeaders().
          set('Authorization', 'basic ' + btoa(this.token));
        }
AmountLimitError(amount:any)
{
  if(amount>=10 && amount<=1000000)
  {
  }
  else{
    return "Enter valid amount (between 10-1000000)";
  }
}    
AmountError(amount:any)
{
  if(amount>=0 && amount<=1000000)
  {
  }
  else{
    return "Enter valid amount (between 0-1000000)";
  }
}  



Mobilenumbervalidation(number:any)
{
  const helper = new JwtHelperService();
  if (localStorage.getItem('VirtualPOSToken') != null) {
    let token :any = localStorage.getItem('VirtualPOSToken');
      let currentUser = helper.decodeToken(token); 
    let userdata = currentUser['http://schemas.microsoft.com/ws/2008/06/identity/claims/userdata'];
    let useridfield = userdata.split('|');
    let merchantCountryCode = useridfield[12].split('MerchantCountryCode:')[1];
  if(merchantCountryCode=="us")
  {
    // var pattern = new RegExp('^[0-9]{3}-[0-9]{3}-[0-9]{4}$');
    var pattern = new RegExp('^[0-9][0-9]{9}$');
    var mobilenumvalidation=pattern.test(number);
    if(mobilenumvalidation ==true)
    {
       return false;
    }
    else
    {
      return true;
    }
  }

  else
  {
    var pattern = new RegExp('^[6-9][0-9]{9}$');
    var mobilenumvalidation=pattern.test(number);
    if(mobilenumvalidation ==true)
    {

      if(number<=9999999999 && number>=1000000000)
      {
        return false;
      }
      else
      {
        return true;
      }
      
    }
    else
    {
      return true;
    }
  }
  }
}
GetCurrencysymbol() {
  const helper = new JwtHelperService();
  if (localStorage.getItem('VirtualPOSToken') != null) {
    let token :any = localStorage.getItem('VirtualPOSToken');
      let currentUser = helper.decodeToken(token);   
    let userdata = currentUser['http://schemas.microsoft.com/ws/2008/06/identity/claims/userdata'];
    let useridfield = userdata.split('|');
    let merchantCountryCode = useridfield[12].split('MerchantCountryCode:')[1];
     if(merchantCountryCode=="us")
     {
      return "$";
     }
     else
     {
      return "₹";
      // return currentUser['₹']
     }
  }
  else {
    return "";
  }
}
GetAuthToken() {
  const helper = new JwtHelperService();
  if (localStorage.getItem('VirtualPOSToken') != null) {
    let token :any = localStorage.getItem('VirtualPOSToken');
      let currentUser = helper.decodeToken(token);   
    let userdata = currentUser['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/hash'];
    return userdata;
     
  }
  else {
    return "";
  }
}


getStatesList()
{
  const helper = new JwtHelperService();
  if (localStorage.getItem('VirtualPOSToken') != null) {
    let token :any = localStorage.getItem('VirtualPOSToken');
      let currentUser = helper.decodeToken(token);    
    let userdata = currentUser['http://schemas.microsoft.com/ws/2008/06/identity/claims/userdata'];
    let useridfield = userdata.split('|');
    let merchantCountryCode = useridfield[12].split('MerchantCountryCode:')[1];
     if(merchantCountryCode=="us")
     {
      return [     
        'Alabama','Alaska', 'Arizona', 'Arkansas','California','Colorado','Connecticut','Delaware','Florida', 
        'Georgia','Hawaii','Idaho','Illinois','Indiana','Iowa','Kansas','Kentucky','Louisiana','Maine',
        'Maryland','Massachusetts','Michigan','Minnesota','Mississippi','Missouri','Montana','Nebraska','Nevada',
        'New Hampshire','New Jersey','New Mexico','New York','North Carolina','North Dakota','Ohio','Oklahoma',
        'Oregon','Pennsylvania','Rhode Island','South Carolina','South Dakota','Tennessee','Texas','Utah','Vermont',
        'Virginia','Washington','West Virginia','Wisconsin','Wyoming'
        ];
        
     }
     else
     {
    return ['Andhra Pradesh','Arunachal Pradesh','Assam','Bihar','Chhattisgarh','Goa','Gujarat',
    'Haryana','Himachal Pradesh','Jammu And Kashmir','Jharkhand','Karnataka','Kerala','Madhya Pradesh','Maharashtra','Manipur',
    'Meghalaya', 'Mizoram','Nagaland','Odisha','Punjab','Rajasthan', 'Sikkim','Tamil Nadu','Telangana','Tripura', 'Uttar Pradesh',
    'Uttarakhand','West Bengal','Andaman And Nicobar','Chandigarh','Dadra And Nagar Haveli','Daman And Diu','Delhi','Lakshadweep',
    'Pondicherry',
    ];
     }
  }
  else {
    return "";
  }
}
}


export interface UserMerchantData {
  MerchantKeyId: number,
  UserId: string,
  UserType: string,
  TempPass: string,
  PasswordExpiryDate: string,
  Status : string,
  ResellerId : number,
  ConfirmedEmail : string,
  ConfirmedMobile:string,
  ContactNo:string,
  AccountLockDate:string,
  MerchantCountryCode:string,
  IsDefaultPassword:number
}
export interface UserPOSData {
  PosSubscriptionId: string,
  UserId: string,
  UserType: string,
  TempPass: string,
  PasswordExpiryDate: string
}

export class Country {
  constructor(public id: number, public countryName: string) { }
}
export class State {
  constructor(public id: number,public countryid:number, public stateName: string) { }
}
export class City {
  constructor( public stateid: number, public cityName: string) { }
  }
export class Units {
  constructor(public id: number,public name: string) { }
}
export class statecities{
  constructor(public id: number, public itemName: string) { }
}
export class permissions{
  constructor(public id: number, public itemName: string) { }
}
export class BusinessTurnOver {
  constructor(public id: number,public amount: string) {}
}
export class BusinessTypes {
  constructor(public id: number,public type: string) {}
}
export class BusinessCategory {
  constructor(public id: number,public type: string) {}
}
export class TimeZone {
  constructor(public id: number,public zoneType: string) {}
}

export class Years {
  constructor(public id: number,public yearsItem: string) {}
}
export class Months {
  constructor(public id: number,public monthsItem: string) {}
}
export class BankCodeTypes {
  constructor(public id: number,public bankcode: string) {}
}
export class BankAccountTypes {
  constructor(public id: number,public bankaccount: string) {}
}
export class TransactionReportTypes {
  constructor(public id: number,public reportType: string) {}
}


