import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { AuthService } from "../services/auth.service";

@Injectable()
export class RyecHttpInterceptor implements HttpInterceptor {
  constructor(public auth: AuthService) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    let applicationId = environment.APPLICATION_ID;
    let securityKey = environment.API_SECURITY_KEY;
    // Clone the request and set the headers
    const modifiedRequest = request.clone({
      setHeaders: {
        Authorization: `Bearer ${this.auth.getToken()}`,
        Platform: "web",
        "application-id": applicationId, //For MRC
        "API-SECURITY-KEY": securityKey, //For MRC
        "Content-Encoding": "gzip",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        "Content-Transfer-Encoding": "gzip",
        "X-Frame-Options": "SAMEORIGIN",
        "Content-Security-Policy":
          "default-src 'self'; script-src 'self' 'unsafe-inline' 'unsafe-eval'; connect-src 'self'; img-src 'self'; style-src 'self' 'unsafe-inline'; font-src 'self' data:;",
      },
    });
    // Pass the modified request to the next handler
    return next.handle(modifiedRequest);
  }
}
