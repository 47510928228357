import { application } from "express";

export const environment = {
  production: true,
  applicationId: 1,
  APP_URL: "https://rajasthancircle.com/",
  RYEC_API_URL: "https://admin.bharatdarshan.org/api/",
  LOCATION_RADIUS: 25,
  APPLICATION_ID: "1",
  API_SECURITY_KEY:
    "basic MTpNWVJBSkFTVEhBTkNMVUI6TVJDLTIwMjI6TVJDOkVORC1VU0VS",

 

  CreateService: 'createDynamicFormSettings',
  InsertPayProfileDocuments: '',
  CustomerSidePayProfileURL: '',
  GetDetailsForAddDataPageService: '',

  GetAddressFromGeolocationService: '',
  UploadDataInBulkWithExcelService: '',
  UploadCKEditorImageAndGetURL: '',
  UpdateBusinessDetails: '',
  SaveListofSmartCollectDetailsService: '',
  GetStatesListService: '',
  GetCityListForStateService: '',
  AddDataToPayProfileService: "",
  AddAddressToPayProfileService: '',
  GetAuthenticationToken: '',
  GetAuthencticationKey: "",
  baseUrl: 'http://127.0.0.1:8000/api',
  clientsidelogflag: 0,
  getRolesPermissions: '',
  getUserPermissions: '',

  APP_NAME: 'MRC',

  indexTitle:'Rajasthan Circle | Lok Sabha Elections 2024: Live Updates, Analysis & Insights',
  indexfevion:'assets/images/logo/fevion.png',
  indexLogo:'https://rajasthancircle.com/assets/images/logo/raj_new_logo.png',

};
