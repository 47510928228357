import { NgModule } from "@angular/core";
import { BrowserModule, provideClientHydration } from "@angular/platform-browser";
import { AppRoutingModule } from "./app-routing.module";
import { ToastrModule } from "ngx-toastr";
import { NgxsModule } from "@ngxs/store";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgxsReduxDevtoolsPluginModule } from "@ngxs/devtools-plugin";
import { NgxsStoragePluginModule } from "@ngxs/storage-plugin";

import { AppComponent } from "./app.component";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import {
  HTTP_INTERCEPTORS,
  HttpClient,
  HttpClientModule,
} from "@angular/common/http";
import { wishlistState } from "./shared/store/states/wishlist.state";
import { categoryState } from "./shared/store/states/category.state";
import { compareState } from "./shared/store/states/compare.state";
import { propertyState } from "./shared/store/states/property-detail.state";
import { imageState } from "./shared/store/states/property-images.state";
import { RyecHttpInterceptor } from "./shared/class/ryec-http-interceptor";
import { HomeService } from "./shared/services/home.service";
import { AuthService } from "./shared/services/auth.service"; 
import { MessageService } from "./shared/services/message.service";
import { WaitingLoaderComponent } from "./components/waiting-loader/waiting-loader.component";
import { CommonFunctionService } from "./services/common-function.service";

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
}

@NgModule({
  declarations: [AppComponent, WaitingLoaderComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    ToastrModule.forRoot(), 
    // Ngxs
    NgxsModule.forRoot([
      wishlistState,
      categoryState,
      
      compareState,
      imageState,
      propertyState,
    ]),
    NgxsReduxDevtoolsPluginModule.forRoot(),
    NgxsStoragePluginModule.forRoot({
      key: ["wishlist", "compare"],
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
  ],
  providers: [
    AuthService,
    MessageService,
    HomeService,
    CommonFunctionService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RyecHttpInterceptor,
      multi: true,
    },
    provideClientHydration(),
  ], 
  exports: [HttpClientModule],
  bootstrap: [AppComponent],
})
export class AppModule {}
