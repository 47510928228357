import { Component, OnInit } from '@angular/core'; 
import { LoaderService } from 'src/app/shared/services/loader.service';

@Component({
  selector: 'app-waiting-loader',
  templateUrl: './waiting-loader.component.html',
  styleUrls: ['./waiting-loader.component.scss']
})
export class WaitingLoaderComponent implements OnInit {
  loading: boolean = false;
  constructor(private loaderService: LoaderService) {
    this.loaderService.isLoading.subscribe((v) => {
      this.loading = v;
    });
  }

  ngOnInit() {
  }

}
