<div style="
    position: absolute;
    display: block;
    background-color: rgba(255, 255, 255, 0.6);
    width: 100%;
    height: 560%;
    z-index: 100000;
  " [hidden]="!loading">
  <div style="
      position: fixed;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    ">
    <div class="loader"></div>
  </div>
</div>
<!-- <img src="assets/images/logo/fevion.png" alt="loading"> -->