import { Component } from '@angular/core';
import { environment } from "src/environments/environment";
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  appId:number = environment.applicationId
  isLoading: boolean = false;
  public title = 'Opulence Livings';
  constructor(){}
}
