import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';


const routes: Routes = [
  {
    path: '',
    redirectTo: '',
    pathMatch: 'full',
  },
  {
    path: '',
    loadChildren: () => import('./components/home/home.module').then((m) => m.HomeModule),
  },

  {
    path: 'pay-profile',
    loadChildren: () => import('./components/home/create-new/create-new.module').then((m) => m.CreateNewModule),
  },
  // {
  //   path: 'membership',
  //   loadChildren: () => import('./components/home/memberships/memberships.module').then((m) => m.MembershipsModule),
  // },

  {
    path: '**',
    redirectTo: 'page/other-pages/404',
    pathMatch: 'full'
  },

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
