<app-waiting-loader *ngIf="!isLoading"></app-waiting-loader>

<head *ngIf="appId== 1">
    <meta charset="utf-8">
    <title id="title">Rajasthan Circle | Lok Sabha Elections 2024: Live Updates, Analysis & Insights</title>
    <base href="/">
    <meta name="viewport" content="width=device-width, initial-scale=1">
    <meta name="description" id="description"
        content="2024 Rajasthan Lok Sabha Election: In-depth analysis, live updates, and insights. Stay ahead with Rajasthan Circle">
    <meta name="keywords"
        content="Rajasthan Elections 2024, Rajasthan Political News, Local News Rajasthan, Rajasthan Cultural Events, Rajasthan Circle News, Latest Updates Rajasthan, Rajasthan Election Insights, Rajasthan Current Events, Explore Rajasthan, Rajasthan Lifestyle News">
    <meta property="og:title" content="test">
    <meta property="og:description" id="descriptionog"
        content="2024 Rajasthan Lok Sabha Election: In-depth analysis, live updates, and insights. Stay ahead with Rajasthan Circle">
    <meta property="og:image" id="logoog" content="https://rajasthancircle.com/assets/images/logo/raj_new_logo.png">
    
  <link rel="icon" type="image/x-icon" id="favicon" href="https://rajasthancircle.com/assets/images/logo/fevion.png">
</head>
<head *ngIf="appId== 4">
    <meta charset="utf-8">
    <title id="title">Easy Law Order</title>
    <base href="/">
    <meta name="viewport" content="width=device-width, initial-scale=1">
    <meta name="description" id="description"
        content="Easy Law Order">
    <meta name="keywords"
        content="Easy Law Order">
    <meta property="og:title" content="Easy Law Order">
    <meta property="og:description" id="descriptionog"
        content="Easy Law Order">
    <meta property="og:image" id="logoog" content="https://easylaworder.com/assets/images/logo/4/logo.svg">
    
    <link rel="icon" type="image/x-icon" id="favicon" href="https://easylaworder.com/assets/images/logo/elo.png">
</head>

<router-outlet *ngIf="!isLoading"></router-outlet>